import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./footer.scss"

import List2 from "../components/list2"
import {Link} from "gatsby"

import Calendar from "../assets/svg/regular/calendar-alt.svg"
import Clock from "../assets/svg/regular/clock.svg"
import Smartphone from "../assets/svg/solid/mobile-alt.svg"
import Telephone from "../assets/svg/solid/phone-alt.svg"
import Mail from "../assets/svg/regular/envelope.svg"
import Pin from "../assets/svg/solid/map-marker-alt.svg"

import FacebookSVG from "../assets/svg/facebook.svg"
import TwitterSVG from "../assets/svg/twitter.svg"
import InstagramSVG from "../assets/svg/instagram.svg"
import LinkedinSVG from "../assets/svg/linkedin.svg"

import ISO9001 from "../images/badge/ISO-9001.png"
import ISO14001 from "../images/badge/ISO-14001.png"
import ISO45001 from "../images/badge/ISO-45001.png"
import PCAB from "../images/badge/pcab.png"
import PCA from "../images/badge/pca.png"

const Footer = ({ siteMetadata }) => {
  const {
    title,
    about,
    email,
    social,
    contact,
    address,
    opening,
    author,
  } = siteMetadata
  // const badges = [
    // { img: ISO14001, alt: "ISO-14001" },
   // { img: ISO9001, alt: "ISO-9001" },
    // { img: ISO45001, alt: "ISO-45001" },
    // { img: PCAB, alt: "PCAB LICENSE" },
   // { img: PCA, alt: "PCA" },
  // ]
  return (
    <footer>
      <section className="footer-wrapper">
        <Container className="footer-content text-white">
          <Row className="section-md">
            {/* <Col className="company-badge" lg="4" sm="12">
              <h4 className="text-center font-weight-bold mt-4 mb-2">
                Ihre Gewerbe Lizenzen
              </h4>
              <section className="img-badge d-flex flex-wrap justify-content-around align-items-center">
                {badges.map(badge => (
                  <img
                    width="90px"
                    className="my-2"
                    src={badge.img}
                    alt={badge.alt}
                  />
                ))}
              </section>
            </Col> */}
            {/* <br></br> */}
          {/*  <Col className="footer-aboutus" lg="6" sm="6">
               <h4 className="ml-5 font-weight-bold mt-4 mb-2">
                Mehr Infos
              </h4> */}
              {/* <p className="font-size-80 text-justify">{about}</p> */}
            {/*  <section className="footer-social ml-5" lg="6" sm="6">
                <Row className="mb-2 ">
                   <a
                    href={social.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookSVG className="fill-svg" />
                  </a> */}
                  {/* <a
                    href={social.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterSVG className="fill-svg" />
                  </a> */}
                  {/* <a
                    href={social.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramSVG className="stroke-svg" />
                  </a> */}
                  {/* <a
                    href={social.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedinSVG className="stroke-svg" />
                  </a> 
                </Row>
              </section>
              <br></br>*/}
              {/* <h6 className="text-center font-weight-bold text-uppercase">Öffnungszeiten Hofladen</h6>
              <section className="opening ml-5">
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Calendar />}
                  value={opening.day}
                />
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Clock />}
                  value={opening.hour}
                />
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Calendar />}
                  value={opening.sams}
                />
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Clock />}
                  value={opening.samstag}
                />
                <hr />
              </section> 
            </Col>*/}
            <Col className="footer-contact " lg="12" sm="12">
              <h4 className="text-left ml-4 font-weight-bold mt-4 mb-2">
                Kontakt
              </h4>
              <ul className="list-unstyled text-left">
                {/* gerold.lotmar@sunrise.ch */}
                <li className="my-2 text-center">
                 

                  <List2  value= {<a className="text-center white-text" href="tel:+443501410">044 350 14 10 </a> }/>

                </li>
                <li className="my-2">
                  <List2 value={<a className="white-text" href="mailto:info@gerold-lotmar.ch">info@gerold-lotmar.ch</a> }/>
                </li>
                
                
              </ul>
            </Col>
          </Row>
        </Container>
        <Row className="footer-credits justify-content-center">
          {` © Gerold-Lotmar 2022`} &nbsp;
         {/* <Link to="/impressum" rel="noopener noreferrer">Gerold Lotmar</Link> */}

        </Row>
      </section>
    </footer>
  )
}

export default Footer
